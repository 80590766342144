import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {Link, navigate} from 'gatsby'
import {Alert} from 'reactstrap'
import {login, fetchUser} from '../redux/actions'
import './auth.css'

class LoginPage extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    error: PropTypes.array,
  }

  state = {
    email: '',
    password: '',
    messages: [],
  }

  handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
			[name]: value,
		})
  }

  componentDidUpdate(prevProps){
    if (this.props.error === prevProps.error && this.props.token === prevProps.token){
      if (this.props.token){
        this.props.fetchUser(this.props.token)
        navigate('/')
      }
      return
    }
    if (this.props.token) {
      this.props.fetchUser(this.props.token)
      navigate('/')
    } else if (this.props.error){
      const messages = []
      const error = this.props.error
      for(let key in error){
        for (let msg in error[key]){
          messages.push(
          <Alert key={key} color="danger">
            {error[key][msg]}
          </Alert>
          )
        }
      }
      this.setState({messages: messages})
    }
  }

  handleFormSubmit = async (event) => {
    event.preventDefault()
    this.props.login(this.state)
  }

  render(){
    const messages = this.state.messages
    return (
      <Container>
        <LoginForm>
        <form css={form} onSubmit={this.handleFormSubmit}>

          <Title>Connexion</Title>

          {messages}
          
          {/* <SocialButtons className="text-center pb-4">
            <Facebook href="#" className="btn btn-primary btn-block btn-lg"><i className="fa fa-facebook"></i> Sign in with <b>Facebook</b></Facebook>
            <Twitter href="#" className="btn btn-info btn-block btn-lg"><i className="fa fa-twitter"></i> Sign in with <b>Twitter</b></Twitter>
          </SocialButtons> */}

          <Separator><I>or</I></Separator>
              <div className="form-group">
                <input type="text" className="form-control" name="email" placeholder="Email" onChange={this.handleInputChange} value={this.state.email} required="required"/>		
              </div>
              <div className="form-group">
                  <input type="password" className="form-control" name="password" placeholder="Password" onChange={this.handleInputChange} value={this.state.password} required="required"/>	
              </div>        
              <div className="form-group mb-5">
                  <Button type="submit" className="btn btn-primary btn-lg">Sign in</Button>
              </div>
          </form>
          <p className="text-center small text-body"> <Link to="/signup" className="text-white">Créer un compte</Link></p>
        </LoginForm>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  error: state.user.loginError,
})

export default connect(mapStateToProps, {login, fetchUser})(LoginPage)



const Container = styled.div`
  color: #fff;
  background: #346bc2;
  height: 100vh;
`
const LoginForm = styled.div`
  width: 310px;
  margin: 0 auto;
  padding: 100px 0 30px;
`

const form = css({
  color: '#999',
  borderRadius: 10,
  marginBottom: 15,
  background: '#fff',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
  padding: 30,
  position: 'relative',
})

const Title = styled.h2`
  font-size: 24px;
  text-align: center;
  color: #454959;
  margin: 10px 0 25px;
`
const Separator = styled.div`
  height: 0;
  margin: 0 auto 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  width: 30%;
`
const I = styled.i`
    padding: 0 10px;
		font-size: 15px;
		text-align: center;
		background: #fff;
		display: inline-block;
		position: relative;
		top: -13px;
		z-index: 1;
`
const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: #99c432;		
  margin-bottom: 20px;
  float: left;
  margin: 1px 0 20px 0;
  width: 100%;
  min-width: 20px;
  font-size: 18px;
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`
const SocialButtons = styled.div`

`
const Facebook = styled.a`
  background: #507cc0;
  border: none;
  &:hover{
    background: #507cc0;
    opacity: 0.8;
  }
`
const Twitter = styled.a`
  background: #64ccf1;
  border: none;
  &:hover{
    background: #64ccf1;
    opacity: 0.8;
  }
`